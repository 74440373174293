import React from "react";
import { NavLink } from "react-router-dom";
// import Cookies from 'universal-cookie';
import HomeNavBar from "./HomeNav/HomeNavBar";

// const cookies = new Cookies();

function Home() {
  // if(cookies.get('uuid')){
  //     window.location='/dashboard';
  // }
  return (
    <>
      <HomeNavBar/>
      <section class="main_home_section">
        <div class="container">
          <div class="effeciantbox">
            <h1>An efficient way to transact.</h1>
            <p>
              Your one stop shop KYC needs, KPLC Postpaid Bill payment, KPLC Tokens and Airtime Purchase
            </p>
            <div class="signup-free-btn">
              <div className="btn signup-free">
                <NavLink to="/Signup">Register </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
