import React, { useState, useEffect } from "react";
import useAuthActions from "../hooks/useAuthActions";

const AuthContext = React.createContext({
  uuID: "",
  fname: "",
  lname: "",
  token: "",
  refreshToken: "",
  email: "",
  mobile: "",
  wallet: {
    balance: 0,
    available: 0,
    accountNumber: "",
  },
  isLoggedIn: false,
  setWalletDetails: (walletDetails) => {},
  setEmail: (email) => {},
  setMobile: (mobile) => {},
  login: (token, refreshToken, fname, lname, email, mobile, uuid) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const { getToken, refreshAuthToken, setAuthTokens, clearAuthTokens } =
    useAuthActions();

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [uuID, setUuID] = useState("");
  const [wallet, setWallet] = useState({
    balance: 0,
    available: 0,
    accountNumber: "",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(!!getToken());

  useEffect(() => {
    const initializeAuth = async () => {
      let currentToken = getToken();

      if (!currentToken) {
        // Attempt to refresh the token if the current one is invalid or missing
        currentToken = await refreshAuthToken();

        if (currentToken) {
          setIsLoggedIn(true);
          setFname(localStorage.getItem("fname") || "");
          setLname(localStorage.getItem("lname") || "");
          setEmail(localStorage.getItem("email") || "");
          setMobile(localStorage.getItem("mobile") || "");
          setUuID(localStorage.getItem("uuid") || "");
        } else {
          setIsLoggedIn(false);
          clearAuthTokens();
        }
      } else {
        setIsLoggedIn(true);
        setFname(localStorage.getItem("fname") || "");
        setLname(localStorage.getItem("lname") || "");
        setEmail(localStorage.getItem("email") || "");
        setMobile(localStorage.getItem("mobile") || "");
        setUuID(localStorage.getItem("uuid") || "");
      }
    };

    initializeAuth();
  }, [getToken, refreshAuthToken, clearAuthTokens]);

  const loginHandler = (
    token,
    refreshToken,
    fname,
    lname,
    email,
    mobile,
    uuid
  ) => {
    setFname(fname);
    setLname(lname);
    setEmail(email);
    setMobile(mobile);
    setUuID(uuid);
    setAuthTokens(token, refreshToken);
    setIsLoggedIn(true);

    localStorage.setItem("fname", fname);
    localStorage.setItem("lname", lname);
    localStorage.setItem("email", email);
    localStorage.setItem("mobile", mobile);
    localStorage.setItem("uuid", uuid);
  };

  const logoutHandler = () => {
    setFname("");
    setLname("");
    setEmail("");
    setMobile("");
    setWallet({ balance: 0, available: 0, accountNumber: "" });
    setIsLoggedIn(false);
    clearAuthTokens();

    localStorage.removeItem("fname");
    localStorage.removeItem("lname");
    localStorage.removeItem("email");
    localStorage.removeItem("mobile");
    localStorage.removeItem("uuid");
  };

  const walletHandler = (walletDetails) => {
    setWallet({
      balance: walletDetails.balance,
      available: walletDetails.available,
      accountNumber: walletDetails.accountNumber,
    });
  };
  const emailHandler = (email) => {
    setEmail(email);
    localStorage.setItem("email", email);
  };

  const mobileHandler = (mobile) => {
    setMobile(mobile);
    localStorage.setItem("mobile", mobile);
  };

  const uuIDHandler = (uuid) => {
    setUuID(uuid);
    localStorage.setItem("uuid", uuid);
  };

  const contextValue = {
    fname,
    lname,
    token: getToken(),
    email,
    mobile,
    uuID,
    wallet,
    isLoggedIn,
    setWalletDetails: walletHandler,
    setEmail: emailHandler,
    setMobile: mobileHandler,
    setUuID: uuIDHandler,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
