import React, { useState } from "react";
import MenuToggle from "./MenuToggle";

import classes from "./MobileLinks.module.css";
import WebLinks from "./WebLinks";

const MobileLinks = (props) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={classes.NavLinksContainer}>
      <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
      {isOpen && (
        <ul className={classes.LinksWrapper}>
          <li className={classes.LinkItem}>
            <a href="#" className={classes.Link}>
              KYC
            </a>
          </li>
          <li className={classes.LinkItem}>
            <a href="#" className={classes.Link}>
              KPLC
            </a>
          </li>
          <li className={classes.LinkItem}>
            <a href="#" className={classes.Link}>
              Airtime
            </a>
          </li>
          <div className={classes.Marginer}></div>
          <WebLinks />
        </ul>
      )}
    </div>
  );
};
export default MobileLinks;
