// useAuthActions.js
import { useCookies } from "react-cookie";
import { encrypt } from "../utilities/encrypt";
import useAuthToken from "../hooks/useAuthToken";

const useAuthActions = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["refreshToken"]);
  const getToken = useAuthToken();

  const refreshAuthToken = async () => {
    const refreshToken = cookies.refreshToken;

    if (!refreshToken) {
      clearAuthTokens();
      return false;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}users/refresh-token/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refreshToken }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to refresh token");
      }

      const data = await response.json();
      const newToken = data.token;

      setCookie("cp_token", encrypt(newToken), { path: "/" });
      return newToken;
    } catch (error) {
      clearAuthTokens();
      return false;
    }
  };

  const clearAuthTokens = () => {
    removeCookie("cp_token", { path: "/" });
    removeCookie("refreshToken", { path: "/" });
  };

  const setAuthTokens = (token, refreshToken) => {
    setCookie("cp_token", encrypt(token), { path: "/" });
    setCookie("refreshToken", refreshToken, { path: "/" });
  };

  return {
    getToken,
    setAuthTokens,
    refreshAuthToken,
    clearAuthTokens,
  };
};

export default useAuthActions;
