import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { createStore, combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
// import "./assets/bootstrap/css/bootstrap.min.css";
// import "./assets/bootstrap/js/bootstrap.bundle.min";

//components
import Home from "./components/homepage/Home";
import PageSpinner from "./components/UI/PageSpinner";

const Login = React.lazy(() => import("./components/homepage/Login"));
const HomePagePayments = React.lazy(() =>
  import("./components/homepage/Payments")
);
const Signup = React.lazy(() => import("./components/homepage/Signup"));
const AccountValidation = React.lazy(() =>
  import("./components/homepage/AccountValidation")
);
const LoginOtp = React.lazy(() => import("./components/homepage/LoginOtp"));
const ForgotPassword = React.lazy(() =>
  import("./components/homepage/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./components/homepage/ResetPassword")
);
const Dashboard = React.lazy(() => import("./components/clientArea/Dashboard"));
const ClientProfile = React.lazy(() =>
  import("./components/clientArea/ClientProfile")
);
const MpesaTopUp = React.lazy(() =>
  import("./components/clientArea/TopUp/MpesaTopUp")
);
const MpesaSend = React.lazy(() =>
  import("./components/clientArea/SendMoney/home")
);
const Airtime = React.lazy(() =>
  import("./components/clientArea/Utilities/Airtime")
);
const KPLC = React.lazy(() => import("./components/clientArea/Utilities/kplc"));
const WalletDetailed = React.lazy(() =>
  import("./components/clientArea/Reports/WalletDetailed")
);
const VerificationChecks = React.lazy(() =>
  import("./components/clientArea/CRB/VerificationChecks")
);
const IPRSResults = React.lazy(() =>
  import("./components/clientArea/CRB/IPRSResults")
);
const CRBResults = React.lazy(() =>
  import("./components/clientArea/CRB/CRBResults")
);

const UtilSuccess = React.lazy(() =>
  import("./components/clientArea/Utilities/UtilSucess")
);

const Payment = React.lazy(() =>
  import("./components/clientArea/Payments/Payments")
);

//Gloabal constants
const reducers = {
  // ... other reducers ...
  toastr: toastrReducer, // <- Mounted at toastr.
};
const reducer = combineReducers(reducers);
const store = createStore(reducer);

function App() {
  return (
    <>
      <Suspense fallback={<PageSpinner />}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/acc-validation" element={<AccountValidation />} />
          <Route exact path="/lotp" element={<LoginOtp />} />
          <Route exact path="/forgotpass" element={<ForgotPassword />} />
          <Route
            exact
            path="resetpassword/:token"
            element={<ResetPassword />}
          />
          <Route exact path="/pmts" element={<HomePagePayments />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/profile" element={<ClientProfile />} />
          <Route exact path="/mpesatopup" element={<MpesaTopUp />} />
          <Route exact path="/mpesasend" element={<MpesaSend />} />
          <Route exact path="/airtime" element={<Airtime />} />
          <Route exact path="/kplc" element={<KPLC />} />
          <Route exact path="/wallet_detailed" element={<WalletDetailed />} />
          <Route exact path="/util_success" element={<UtilSuccess />} />
          <Route exact path="/vchecks" element={<VerificationChecks />} />
          <Route exact path="/iprs_results" element={<IPRSResults />} />
          <Route exact path="/crb_results" element={<CRBResults />} />
          <Route exact path="/payments" element={<Payment />} />
        </Routes>
      </Suspense>
      <Provider store={store}>
        <div>
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            getState={(state) => state.toastr} // This is the default
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
        </div>
      </Provider>
    </>
  );
}

export default App;
