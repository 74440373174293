import { useCookies } from "react-cookie";
import { decrypt } from "../utilities/decrypt";

const useAuthToken = () => {
  const [cookies] = useCookies(["cp_token"]);

  const getToken = () => {
    const encryptedToken = cookies.cp_token;
    if (encryptedToken) {
      return decrypt(encryptedToken);
    }
    return null;
  };

  return getToken;
};

export default useAuthToken;
