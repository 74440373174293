import React from "react";
import classes from "./NavLinks.module.css";

const NavLinks = (props) => {
  return (
    <div className={classes.NavLinksContainer}>
      <ul className={classes.LinksWrapper}>
        <li className={classes.LinkItem}>
          <a href="#" className={classes.Link}>
            KYC
          </a>
        </li>
        <li className={classes.LinkItem}>
          <a href="#" className={classes.Link}>
            KPLC
          </a>
        </li>
        <li className={classes.LinkItem}>
          <a href="#" className={classes.Link}>
            Airtime
          </a>
        </li>
      </ul>
    </div>
  );
};

export default NavLinks;
