const CryptoJS = require("crypto-js");

export const encrypt = (str) => {
  // Generate a random IV
  const iv = CryptoJS.lib.WordArray.random(128 / 8);

  const key = CryptoJS.enc.Utf8.parse(process.env.SECRET_KEY).toString();

  // Encrypt the string using AES with the secret key and IV
  const encrypted = CryptoJS.AES.encrypt(str, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  // Combine the IV and the encrypted string (both need to be in Base64 format for easy concatenation and transport)
  const encryptedStrWithIv = `${iv.toString(
    CryptoJS.enc.Base64
  )}:${encrypted.toString()}`;
  return encryptedStrWithIv;
};
