
import React from "react";
import CrystalPayLogoImg from "./logo.svg";
import classes from "./Logo.module.css"

const Logo = (props) => {
  return (
    <div className={classes.LogoWrapper}>
      <div className={classes.LogoImg}>
        <img src={CrystalPayLogoImg} className={classes.Img} alt="Crystal Pay logo" />
      </div>
    </div>
  );
};
export default Logo;
