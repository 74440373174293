import React from "react";
import { useMediaQuery } from "react-responsive";

//Components 
import { NavLink } from "react-router-dom";
import { DeviceSize } from "../../UI/DeviceSize";
import classes from "./HomeNavBar.module.css";
import Logo from "../../UI/Logo";
import NavLinks from "./NavLinks";
import WebLinks from "./WebLinks";
import MobileLinks from "./MobileLinks";

const HomeNavBar = (props) => {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  return (
    <div className={classes.NavbarContainer}>
      <div className={classes.LeftSection}>
        <NavLink to="/">
          <Logo />
        </NavLink>
      </div>
      <div className={classes.MiddleSection}>{!isMobile && <NavLinks />} </div>
      <div className={classes.RightSection}>
        {!isMobile && <WebLinks />}
        {isMobile && <MobileLinks />}
      </div>
    </div>
  );
};
export default HomeNavBar;
