import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./WebLinks.module.css";
const WebLinks = (props) => {
  return (
    <div className={classes.WebLinksContainer}>
      <ul className={classes.LinksWrapper} >
        <li className={classes.LinkItem}>
          <NavLink to="/Login" className={classes.homebtn}>
            Login{" "}
          </NavLink>
        </li>
        <li className={classes.LinkItem}>
          <NavLink to="/signup" className={classes.homebtn}>
            Register{" "}
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
export default WebLinks;
