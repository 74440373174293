const CryptoJS = require("crypto-js");

export const decrypt = (encryptedStrWithIv) => {
  const parts = encryptedStrWithIv.split(":");
  if (parts.length !== 2) {
    console.error("Decryption error: Invalid encrypted string format.");
    return ""; // Or handle the error appropriately
  }

  const iv = CryptoJS.enc.Base64.parse(parts[0]);
  const key = CryptoJS.enc.Utf8.parse(process.env.SECRET_KEY).toString();

  const decrypted = CryptoJS.AES.decrypt(parts[1], key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};
